import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

import { TableCellProps } from '../../utils/types';

const useStyles = makeStyles(() => ({
  cellContainer: {
    cursor: 'pointer',
    color: '#006D77',
    fontWeight: 500,
  },
}));

const NavigationCell = ({ value }: TableCellProps): JSX.Element => {
  const classes = useStyles();
  return <Typography className={classes.cellContainer}>{value}</Typography>;
};

export default NavigationCell;
