import { Chip } from '@material-ui/core';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import * as React from 'react';

import { PermissionsType } from '../../utils/types';
import { LabelPairCell } from '../common/Cells';
import DropdownCell from '../common/DropDownCell';
import MaskCell from '../common/MaskCell';
import NavigationCell from '../common/NavigationCell';
import { OrganizationStatusCell } from './Cells';

const booleanCellOptions = ['true', 'false'];

export const getColumns = ({
  canUpdateOrganization,
}: Pick<PermissionsType, 'canUpdateOrganization'>): GridColDef[] => [
  {
    headerName: 'ID',
    field: 'id',
    hide: true,
  },
  {
    headerName: 'Name & Domain',
    field: 'organization_name_and_domain',
    width: 250,
    renderCell: (params: GridRenderCellParams) => (
      <LabelPairCell
        labelTop={params.row.organization_name}
        labelBottom={params.row.organization_domain}
        highlight='labelBottom'
      />
    ),
  },
  {
    headerName: 'Total Users',
    field: 'total_users',
    width: 160,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Total Admins',
    field: 'total_admin_users',
    width: 170,
    type: 'number',
    headerAlign: 'left',
    align: 'center',
    valueFormatter: ({ value }) => value || 0,
    renderCell: ({ value }) => <NavigationCell value={value} />,
  },
  {
    headerName: 'Allow org level crawls',
    field: 'is_org_crawl_enabled',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Allow other domain users',
    field: 'allow_other_domain_users',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    hide: true,
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Auto add domain users',
    field: 'auto_add_domain_users',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    hide: true,
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'Allow sharing outside organization',
    field: 'allow_sharing_outside_org',
    width: 250,
    editable: canUpdateOrganization,
    valueOptions: booleanCellOptions,
    type: 'singleSelect',
    hide: true,
    valueGetter: ({ value }) => (value ? 'true' : 'false'),
    ...(canUpdateOrganization && {
      renderCell: ({ value }) => <DropdownCell value={value} />,
    }),
  },
  {
    headerName: 'API Access Key',
    field: 'api_access_key',
    width: 500,
    renderCell: ({ value }) => <MaskCell value={value} />,
  },
  {
    headerName: 'Status',
    field: 'org_status',
    width: 250,
    renderCell: ({ row }) => (
      <OrganizationStatusCell
        isDeletionPending={!row.data_deleted && row.delete_data}
        organizationId={row.id}
      />
    ),
  },
];
