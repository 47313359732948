import React from 'react';

import { ROLES } from '../Components/common/userRoles';
import { useFetchUserActivity } from '../Components/UserActivity/useFetchUserActivity';
import { useFetchOrganization } from '../Components/Users/useFetchOrganization';
import { useFetchAppInfo } from './useFetchAboutApps';

export const useCheckRBACDelink = (
  source: string,
  organizationId: string,
  userId: string
) => {
  const { isLoading: orgLoading, data: organization } = useFetchOrganization(
    parseInt(organizationId, 10)
  );
  const { isLoading: userActivityLoading, userActivity } = useFetchUserActivity(
    organizationId,
    userId
  );
  const { isLoading: appLoading, app } = useFetchAppInfo(source);

  const isLoading = orgLoading || userActivityLoading || appLoading;

  const isRBACDelink = React.useMemo(() => {
    if (isLoading || !organization || !app || !userActivity) return false;

    return (
      organization.is_org_crawl_enabled &&
      app.is_org_crawl_supported &&
      userActivity.role === ROLES.ADMIN
    );
  }, [organization, app, userActivity, isLoading]);

  return { isLoading, isRBACDelink };
};
