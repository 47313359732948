import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { CONNECT_API_ROUTES } from '../api-routes/connect';
import { axiosInstance } from '../axios';

const QUERY_KEY = ['private_apps'];

export const useFetchAboutApps = () => {
  return useQuery(QUERY_KEY, fetchAboutApps, {
    select: (data) => data.data.results,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

const fetchAboutApps = async () =>
  axiosInstance.get<ResponseType>(CONNECT_API_ROUTES.aboutApps());

export const useInvalidateFetchAboutApps = () => {
  const queryClient = useQueryClient();
  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
};

export const useFetchAppInfo = (source: string) => {
  const { isLoading: privateAppsLoading, data: privateApps } =
    useFetchAboutApps();

  const app = React.useMemo(
    () => privateApps?.find((app) => app.label === source),
    [privateApps, source]
  );

  return { isLoading: privateAppsLoading, app };
};

type ResponseType = {
  results: {
    label: string;
    source: string;
    sourcetype: string;
    is_org_crawl_supported: boolean;
  }[];
};
