/* eslint-disable jsx-a11y/no-autofocus */
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  contentContainer: {
    minWidth: '300px',
    paddingTop: '8px',
  },
}));

type ConfirmationDialogPropsType = {
  show: boolean;
  isLoading?: boolean;
  closeDialog: () => void;
  actionHandler: () => void;
  errorMessage?: string;
  results?: Record<string, any>[];
  title?: string;
  description?: string | React.ReactNode;
  primaryButtonText?: string;
  primaryButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default';
  primaryButtonVariant?: 'text' | 'outlined' | 'contained';
  secondaryButtonText?: string;
  secondaryButtonColor?: 'inherit' | 'primary' | 'secondary' | 'default';
  secondaryButtonVariant?: 'text' | 'outlined' | 'contained';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const ResultsDialog = ({
  show,
  closeDialog,
  results,
}: Pick<
  ConfirmationDialogPropsType,
  'results' | 'show' | 'closeDialog'
>): JSX.Element => {
  return (
    <Dialog open={show} onClose={closeDialog}>
      <DialogTitle>Results</DialogTitle>
      <DialogContent>
        {results.map(({ id, message }) => (
          <Box key={id} mb={2}>
            <Typography variant='h6' color='primary'>
              {id}
            </Typography>
            <Typography variant='body1' color='textPrimary'>
              {message}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const ConfirmationDialog = ({
  show,
  isLoading,
  closeDialog,
  actionHandler,
  errorMessage,
  results = [],
  title = 'Alert',
  description = 'Are you sure ?',
  primaryButtonText = 'Yes',
  primaryButtonColor = 'primary',
  primaryButtonVariant = 'contained',
  secondaryButtonColor = 'default',
  secondaryButtonVariant = 'outlined',
  secondaryButtonText,
  maxWidth = 'xs',
}: ConfirmationDialogPropsType): JSX.Element => {
  const classes = useStyles();

  if (results.length > 0) {
    return (
      <ResultsDialog show={show} closeDialog={closeDialog} results={results} />
    );
  }

  return (
    <Dialog maxWidth={maxWidth} fullWidth open={show} onClose={closeDialog}>
      <DialogTitle>
        <Typography variant='h6' color='primary' component='span'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant='body1'
          color='textPrimary'
          className={classes.contentContainer}
        >
          {errorMessage || description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant={secondaryButtonVariant}
          color={secondaryButtonColor}
          onClick={closeDialog}
        >
          {!errorMessage ? secondaryButtonText || 'No' : 'Cancel'}
        </Button>
        <Button
          variant={primaryButtonVariant}
          color={primaryButtonColor}
          onClick={actionHandler}
          disabled={!!errorMessage || isLoading}
        >
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
