import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { capitalizeFirstLetterOfEachWord } from '../../utils/textUtils';
import {
  PrivateSourceType,
  PublicSourceType,
  UserBasicInfoType,
} from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import { VerticalDivider } from '../common/VerticalDivider';
import { useTableColumns } from './columns';
import { useFetchUserActivity } from './useFetchUserActivity';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
    width: '99%',
    marginTop: '20px',
  },
  publicTableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '660px',
    width: '520px',
    marginTop: '20px',
  },
  detailsContainer: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

export type RouteParam = {
  organizationId: string;
  userId: string;
};

type UserDetailsPropsType = {
  details: UserBasicInfoType;
};

type PrivateIntegrationsTablePropsType = {
  private_sources: PrivateSourceType[];
  canDelinkIntegrations: boolean;
};

type PublicIntegratiobsTablePropsType = {
  public_sources: PublicSourceType[];
};

const UserDetails = ({ details }: UserDetailsPropsType) => {
  const classes = useStyles();
  const {
    username,
    email,
    profession,
    industry,
    plan,
    feeds_created,
    feeds_shared,
    signup_method,
  } = details;

  return (
    <Box className={classes.detailsContainer}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} style={{ paddingRight: '16px' }}>
          <Typography className={classes.field}>
            <span className={classes.label}>Username:</span>{' '}
            {capitalizeFirstLetterOfEachWord(username)}
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Email:</span> {email}
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Profession:</span> {profession}
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Industry:</span> {industry}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item md={1}>
            <VerticalDivider height={150} />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={3} style={{ paddingLeft: '16px' }}>
          <Typography className={classes.field}>
            <span className={classes.label}>SignUp Method:</span>
            <Chip
              label={capitalizeFirstLetterOfEachWord(signup_method)}
              color='primary'
              size='small'
              className={classes.chip}
            />
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Plan:</span>
            <Chip label={plan} size='small' className={classes.chip} />
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Feeds Created:</span>
            {feeds_created}
          </Typography>
          <Typography className={classes.field}>
            <span className={classes.label}>Feeds Shared:</span> {feeds_shared}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const PrivateIntegrationsTable = ({
  private_sources,
  canDelinkIntegrations,
}: PrivateIntegrationsTablePropsType): JSX.Element => {
  const classes = useStyles();
  const columns = useTableColumns('private', canDelinkIntegrations);
  const rows = private_sources.map((app) => ({
    id: app.first_linked,
    ...app,
  }));

  return (
    <Box className={classes.tableContainer}>
      <Typography variant='h5'>App Integrations:</Typography>
      <Box className={classes.tableWrapper}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          rows={rows}
          columns={columns}
          autoPageSize
        />
      </Box>
    </Box>
  );
};

const PublicIntegrationsTable = ({
  public_sources,
}: PublicIntegratiobsTablePropsType): JSX.Element => {
  const classes = useStyles();
  const columns = useTableColumns('public', false);
  const rows = public_sources.map((source) => ({
    id: source.key,
    ...source,
  }));

  return (
    <Box className={classes.tableContainer}>
      <Typography variant='h5'>Public Integrations:</Typography>
      <Box className={classes.publicTableWrapper}>
        <DataGrid
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
          rows={rows}
          columns={columns}
          autoPageSize
        />
      </Box>
    </Box>
  );
};

const UserActivity = (): JSX.Element => {
  const classes = useStyles();
  const { organizationId, userId } = useParams<RouteParam>();
  const { isLoading, userActivity } = useFetchUserActivity(
    organizationId,
    userId
  );
  const {
    permissions: { canDelinkIntegrations },
    isLoading: permissionsLoading,
  } = useGetPermissions();

  return (
    <Box className={classes.rootContainer}>
      <Typography variant='h4'>User Activity</Typography>
      {isLoading || permissionsLoading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UserDetails details={userActivity} />
          <PrivateIntegrationsTable
            private_sources={userActivity.private_apps}
            canDelinkIntegrations={canDelinkIntegrations}
          />
          <PublicIntegrationsTable public_sources={userActivity.public_apps} />
        </>
      )}
    </Box>
  );
};

export default UserActivity;
