import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../axios';
import { OptionsType, UserDetailsType } from '../../utils/types';

type ResponseType = {
  results: {
    users: UserDetailsType[];
    options: OptionsType;
  };
};

type ReturnType = {
  isLoading: boolean;
  users: UserDetailsType[];
  options: OptionsType;
};

const QUERY_KEY = 'users';
const fetchUsers = (organizationId: string) =>
  axiosInstance.get<ResponseType>(`/organizations/${organizationId}/users`);

export const useFetchUsers = (organizationId: string): ReturnType => {
  const { data, isLoading } = useQuery([QUERY_KEY, organizationId], () =>
    fetchUsers(organizationId)
  );
  const results = data?.data.results;

  return {
    isLoading,
    users: results?.users || [],
    options: results?.options,
  };
};

export const useInvalidateFetchUsers = () => {
  const queryClient = useQueryClient();
  return (organizationId: string) => {
    queryClient.invalidateQueries([QUERY_KEY, organizationId]);
  };
};
