import { rest } from 'msw';

const ALL_USERS_DATA = [
  {
    id: 'f0799a81-905e-4870-a13a-4a99b8e77c67',
    username: 'wbenadette0',
    email: 'cmettrick0@imdb.com',
    profession: 'Cost Accountant',
    industry: 'Research and Development',
    country: 'China',
    plan: 'TEAM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: true,
    data_deleted: false,
    total_storage: 14.97,
    no_of_private_sources: 1,
    no_of_public_sources: 14,
  },
  {
    id: '9012d826-32ba-4746-ae92-e5514701e5f6',
    username: 'kallam1',
    email: 'iyukhov1@pagesperso-orange.fr',
    profession: 'Payment Adjustment Coordinator',
    industry: 'Support',
    country: 'Haiti',
    plan: 'TEAM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 10.64,
    no_of_private_sources: 2,
    no_of_public_sources: 0,
  },
  {
    id: 'e7825f20-feb0-42de-b5e2-5e782c12680a',
    username: 'mivanaev2',
    email: 'icicullo2@nature.com',
    profession: 'Clinical Specialist',
    industry: 'Accounting',
    country: 'Nigeria',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 22.82,
    no_of_private_sources: 6,
    no_of_public_sources: 20,
  },
  {
    id: '2dd2107a-a585-4bac-b6c7-320e35ef432d',
    username: 'hgabb3',
    email: 'mmacmenemy3@sphinn.com',
    profession: 'Speech Pathologist',
    industry: 'Services',
    country: 'Palestinian Territory',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 22.2,
    no_of_private_sources: 8,
    no_of_public_sources: 2,
  },
  {
    id: 'f7c979e5-ed6e-40c0-b434-64e024ae2c6d',
    username: 'ahalbord4',
    email: 'balmak4@deliciousdays.com',
    profession: 'Librarian',
    industry: 'Product Management',
    country: 'Indonesia',
    plan: 'FREE',
    role: 'superadmin',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 5.84,
    no_of_private_sources: 1,
    no_of_public_sources: 4,
  },
  {
    id: 'd0556b1e-658b-40ad-ba62-d842d2c63a24',
    username: 'gblacklock5',
    email: 'ndullard5@kickstarter.com',
    profession: 'Legal Assistant',
    industry: 'Services',
    country: 'China',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 43.15,
    no_of_private_sources: 2,
    no_of_public_sources: 14,
  },
  {
    id: 'ad7e13bd-75b3-48a2-9d39-811bdaf3bcf6',
    username: 'aarndt6',
    email: 'jnewvill6@hp.com',
    profession: 'Engineer I',
    industry: 'Training',
    country: 'Nigeria',
    plan: 'FREE',
    role: 'support',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 45.6,
    no_of_private_sources: 6,
    no_of_public_sources: 17,
  },
  {
    id: 'bb44ed12-d941-4931-8786-3023a1ad95c4',
    username: 'fjickles7',
    email: 'ksonley7@hubpages.com',
    profession: 'Nurse Practicioner',
    industry: 'Legal',
    country: 'Democratic Republic of the Congo',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 24.92,
    no_of_private_sources: 4,
    no_of_public_sources: 16,
  },
  {
    id: '744760d8-0e6a-4e67-bba6-781e60162bbc',
    username: 'rtrawin8',
    email: 'sdowngate8@mapy.cz',
    profession: 'Geological Engineer',
    industry: 'Legal',
    country: 'Syria',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 4.49,
    no_of_private_sources: 8,
    no_of_public_sources: 11,
  },
  {
    id: '029e58f1-8f14-4a5a-ba1e-6839746c1476',
    username: 'smallett9',
    email: 'dscreas9@drupal.org',
    profession: 'Software Test Engineer II',
    industry: 'Product Management',
    country: 'Pakistan',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 52.73,
    no_of_private_sources: 3,
    no_of_public_sources: 16,
  },
  {
    id: 'e6d7fc21-4433-4eed-9d58-94b6ab34a808',
    username: 'pkornysheva',
    email: 'gbrecknocka@51.la',
    profession: 'Human Resources Manager',
    industry: 'Research and Development',
    country: 'Indonesia',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 29.84,
    no_of_private_sources: 4,
    no_of_public_sources: 7,
  },
  {
    id: '46e95561-2432-40d5-9ce5-96ff19515ab0',
    username: 'mbraynb',
    email: 'dguiduzzib@twitpic.com',
    profession: 'Financial Analyst',
    industry: 'Sales',
    country: 'Tanzania',
    plan: 'TEAMS',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 35.37,
    no_of_private_sources: 3,
    no_of_public_sources: 7,
  },
  {
    id: '4d9b3ebe-74c4-481f-bd66-cedb147def28',
    username: 'dscamblerc',
    email: 'nclanceyc@arstechnica.com',
    profession: 'Executive Secretary',
    industry: 'Legal',
    country: 'Portugal',
    plan: 'TEAMS',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 33.65,
    no_of_private_sources: 6,
    no_of_public_sources: 9,
  },
  {
    id: '7988bc34-9bdb-404b-8220-42994ea59999',
    username: 'tlanghorned',
    email: 'brutterd@163.com',
    profession: 'Web Developer IV',
    industry: 'Marketing',
    country: 'Philippines',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 37.47,
    no_of_private_sources: 6,
    no_of_public_sources: 0,
  },
  {
    id: '669e2b3e-20d9-42d4-b682-c3387145d213',
    username: 'vcomforde',
    email: 'oyaneze@auda.org.au',
    profession: 'Editor',
    industry: 'Research and Development',
    country: 'Canada',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 47.7,
    no_of_private_sources: 2,
    no_of_public_sources: 19,
  },
  {
    id: 'cacc93fd-0e3b-440b-acf1-822366d8d6ef',
    username: 'cschoenfischf',
    email: 'efosterf@un.org',
    profession: 'Graphic Designer',
    industry: 'Human Resources',
    country: 'Peru',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    total_storage: 4.52,
    no_of_private_sources: 3,
    no_of_public_sources: 0,
  },
  {
    id: 'a6fbadec-89b1-479f-99ff-f60cf2be0757',
    username: 'owharramg',
    email: 'mbernardesg@t-online.de',
    profession: 'Chemical Engineer',
    industry: 'Human Resources',
    country: 'China',
    plan: 'premium',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 12.59,
    no_of_private_sources: 6,
    no_of_public_sources: 0,
  },
  {
    id: '571c7202-7c93-49b3-a479-d00f9907f6a0',
    username: 'graffh',
    email: 'bhurlinh@rediff.com',
    profession: 'Software Test Engineer III',
    industry: 'Training',
    country: 'Brazil',
    plan: 'TEAMS',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 26.42,
    no_of_private_sources: 3,
    no_of_public_sources: 9,
  },
  {
    id: '301a8d6a-b99e-48ee-bf65-45b4b03a38fe',
    username: 'dgreiri',
    email: 'bislipi@posterous.com',
    profession: 'Graphic Designer',
    industry: 'Engineering',
    country: 'Brazil',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 32.15,
    no_of_private_sources: 6,
    no_of_public_sources: 14,
  },
  {
    id: '5cd9c3f0-fb1d-4da8-a24b-0bbde4bdb6fa',
    username: 'hblakesleyj',
    email: 'npridittj@sohu.com',
    profession: 'Legal Assistant',
    industry: 'Product Management',
    country: 'Canada',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 3.82,
    no_of_private_sources: 8,
    no_of_public_sources: 12,
  },
  {
    id: 'bfa3cc26-5c74-43bd-9951-72a11ed321fe',
    username: 'jandreazzik',
    email: 'kcashellk@topsy.com',
    profession: 'Executive Secretary',
    industry: 'Accounting',
    country: 'Russia',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 1.6,
    no_of_private_sources: 6,
    no_of_public_sources: 11,
  },
  {
    id: '05291602-2cf5-44af-8f94-7e9376ec97de',
    username: 'rsysonl',
    email: 'ghullsl@ibm.com',
    profession: 'Social Worker',
    industry: 'Services',
    country: 'China',
    plan: 'FREE',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 2.34,
    no_of_private_sources: 8,
    no_of_public_sources: 0,
  },
  {
    id: 'f445e7f4-982e-4e2d-a015-00194ff363d0',
    username: 'tagronm',
    email: 'fdoellem@gmpg.org',
    profession: 'Financial Advisor',
    industry: 'Support',
    country: 'Argentina',
    plan: 'PREMIUM',
    role: 'member',
    signed_up: '2021-05-10 15:15:19.835+00',
    delete_data: false,
    data_deleted: false,
    total_storage: 28.04,
    no_of_private_sources: 3,
    no_of_public_sources: 19,
  },
];

const privateIntegrations = [
  {
    source: 'whatsapp',
    verifiable_id: '+919999999999',
    connected: true,
    token_valid: false,
    last_crawled_at: '2021-08-17 10:12:17.271+00',
    crawler_preferences_selected: true,
    storage: 1.1,
    first_linked: '2021-08-17 10:12:17.271+00',
  },
  {
    source: 'telegram',
    verifiable_id: '+919999999999',
    connected: false,
    token_valid: true,
    last_crawled_at: '2021-08-17 10:12:17.271+00',
    crawler_preferences_selected: false,
    storage: 5.6,
    first_linked: '2021-05-10 15:15:19.835+00',
  },
  {
    source: 'gmail',
    verifiable_id: 'test4@test.com',
    connected: true,
    token_valid: true,
    last_crawled_at: '2021-08-17 10:12:17.271+00',
    crawler_preferences_selected: '-',
    storage: 2.3,
    first_linked: '2021-09-08 05:33:19.555+00',
  },
  {
    source: 'onedrive',
    verifiable_id: 'test4@test.com',
    connected: true,
    token_valid: true,
    last_crawled_at: '2021-08-17 10:12:17.271+00',
    crawler_preferences_selected: false,
    storage: 6.5,
    first_linked: '2021-08-16 07:38:26.622+00',
  },
];

const privateApps = [
  {
    label: 'whatsApp',
    source: 'Whatsapp',
    sourcetype: 'private',
    is_org_crawl_supported: false,
  },
  {
    label: 'telegram',
    source: 'Telegram',
    sourcetype: 'private',
    is_org_crawl_supported: false,
  },
  {
    label: 'gmail',
    source: 'Gmail',
    sourcetype: 'private',
    is_org_crawl_supported: true,
  },
  {
    label: 'onedrive',
    source: 'OneDrive',
    sourcetype: 'private',
    is_org_crawl_supported: false,
  },
];

const publicIntegrations = [
  {
    key: 'ft',
    label: 'Financial Times',
    type: 'Needl',
  },
  {
    key: 'mit.tech.review',
    label: 'MIT Tech Review',
    type: 'Needl',
  },
  {
    key: '8ca69e914509cb68ebbb5cf75b99f26d',
    label: 'Kent C. Dodds',
    type: 'Feedly',
  },
  {
    key: 'awealthofcommonsense',
    label: 'A Wealth of Common Sense',
    type: 'Needl',
  },
  {
    key: 'reuters',
    label: 'Reuters',
    type: 'Needl',
  },
  {
    key: '037a774e24a5a4c130e91247a2f1b7cc',
    label: 'Newsletter feed for Kent C. Dodds News',
    type: 'Feedly',
  },
  {
    key: 'bse_filings',
    label: 'BSE Filings',
    type: 'Needl',
  },
  {
    key: 'bse_presentations',
    label: 'BSE Presentations',
    type: 'Needl',
  },
  {
    key: 'bse_annual_reports',
    label: 'BSE Annual Reports',
    type: 'Needl',
  },
  {
    key: 'bse_conf_calls',
    label: 'Earnings Call Transcripts - India',
    type: 'Needl',
  },
  {
    key: 'c81b7fb6acab89d398e031724c992df1',
    label: 'Business Standard',
    type: 'Needl',
  },
];

const ADMIN_USERS_DATA = [
  {
    id: 'd0e9a459-fa92-4f87-9d23-fe0c60fd1dc0',
    name: 'cmussard0',
    email: 'klutman0@cornell.edu',
    userRole: 'superadmin',
  },
  {
    id: 'a286e64a-f885-44a8-8fb5-f3d9f27af49f',
    name: 'esiaspinski1',
    email: 'rupsale1@icq.com',
    userRole: undefined,
  },
  {
    id: 'bb4f4e2e-520e-4270-a356-d7b004c6cbfd',
    name: 'kgalbreath2',
    email: 'olandes2@cargocollective.com',
    userRole: 'support',
  },
  {
    id: '5ce803e4-004a-4af9-b488-797ef90a1d9d',
    name: 'eeggerton3',
    email: 'hneed3@nasa.gov',
    userRole: undefined,
  },
  {
    id: '79fc7824-57fe-4bc6-82f6-58c84bfbbe4f',
    name: 'hrudgley4',
    email: 'lhelgass4@mlb.com',
    userRole: undefined,
  },
  {
    id: 'e59d090b-a268-454f-9817-c5192a4c94d7',
    name: 'rlively5',
    email: 'hkemme5@army.mil',
    userRole: 'superadmin',
  },
  {
    id: '977367d8-0338-4973-85b8-172ac5f8c817',
    name: 'kdumbrell6',
    email: 'lgronw6@europa.eu',
    userRole: undefined,
  },
  {
    id: '25aba3c8-d6c7-40b7-abcb-484d3b19ab92',
    name: 'rlalley7',
    email: 'ncoy7@epa.gov',
    userRole: undefined,
  },
  {
    id: '005d6153-eaec-41cb-ba5f-6b31ae9b5591',
    name: 'cmullinger8',
    email: 'lmintoft8@ucoz.com',
    userRole: undefined,
  },
  {
    id: '2c30cd06-6f12-4d8c-9af5-bf774b6cb300',
    name: 'efrankum9',
    email: 'cduquesnay9@pcworld.com',
    userRole: 'support',
  },
  {
    id: 'd33470fe-8f0f-40e0-9756-6feda0588ede',
    name: 'jspinoa',
    email: 'cledwitha@umn.edu',
    userRole: undefined,
  },
  {
    id: '45191a26-118f-417b-86d5-0064b20c32a9',
    name: 'cbinningb',
    email: 'bclellb@latimes.com',
    userRole: 'support',
  },
  {
    id: '0c0f7f18-216a-4ff8-a028-ebc46c77094c',
    name: 'wnewingc',
    email: 'smatysiakc@latimes.com',
    userRole: undefined,
  },
  {
    id: '52ca01e6-59bf-44aa-bb0d-d6e21b96eb93',
    name: 'jagentd',
    email: 'psolanod@quantcast.com',
    userRole: undefined,
  },
  {
    id: '489f5f67-5bb3-4032-9b0b-fe64aebc40a5',
    name: 'sbolline',
    email: 'lwegenene@vistaprint.com',
    userRole: 'support',
  },
  {
    id: '21980dd2-86ae-4388-afbc-63c75c71cb29',
    name: 'askulletf',
    email: 'ldevillef@ucoz.ru',
    userRole: undefined,
  },
];

const organizations = [
  {
    id: 1,
    organization_name: 'Default',
    organization_domain: null,
    total_users: 3,
    total_admin_users: 0,
    allow_other_domain_users: true,
    auto_add_domain_users: false,
    allow_sharing_outside_org: true,
    is_org_crawl_enabled: true,
    delete_data: false,
    data_deleted: false,
    api_access_key: '89e27ed2-b0c3-4aac-882d-4c9e5c50fe62',
  },
  {
    id: 2,
    organization_name: 'needl',
    organization_domain: 'needl.ai',
    total_users: 2,
    total_admin_users: 0,
    allow_other_domain_users: false,
    auto_add_domain_users: true,
    allow_sharing_outside_org: false,
    is_org_crawl_enabled: false,
    delete_data: true,
    data_deleted: false,
    api_access_key: '5cc691e6-ea77-45ef-99a2-816427ab028f',
  },
  {
    id: 3,
    organization_name: 'Oaklane Capital',
    organization_domain: 'oaklanecapital.com',
    total_users: 2,
    total_admin_users: 1,
    allow_other_domain_users: false,
    auto_add_domain_users: true,
    allow_sharing_outside_org: false,
    is_org_crawl_enabled: false,
    delete_data: false,
    data_deleted: false,
    api_access_key: '09ce1d5f-86af-4632-93d2-86865eb0267d',
  },
];

const publicSources = [
  {
    id: '59069d1e9e02671d368d68e99671314c',
    label: 'Troy Hunt',
    link_to_crawl: 'http://www.troyhunt.com/feeds/posts/default',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/59069d1e9e02671d368d68e99671314c',
  },
  {
    id: '6770f5315db48fcfa8decf79e1c7efb6',
    label: 'The Incidential Economist',
    link_to_crawl: 'https://theincidentaleconomist.com/wordpress/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/6770f5315db48fcfa8decf79e1c7efb6',
  },
  {
    id: 'thelancet',
    label: 'The Lancet',
    link_to_crawl: 'http://www.thelancet.com/rssfeed/lancet_current.xml',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/thelancet',
  },
  {
    id: '8de6ed613d302ed0f07ff9147ea370fb',
    label: 'PV Tech',
    link_to_crawl: 'http://www.pv-tech.org/rss',
    thumbnail_location: null,
  },
  {
    id: '7d288d3daa5e1107f01c18ed6449fa69',
    label: 'Journal du Geek',
    link_to_crawl: 'http://www.journaldugeek.com/rss.php',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/7d288d3daa5e1107f01c18ed6449fa69',
  },
  {
    id: '2ec6d9f229eea922c1a6e3748be8e216',
    label: 'Houzz',
    link_to_crawl: 'http://www.houzz.fr/getGalleries/featured/out-rss',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/2ec6d9f229eea922c1a6e3748be8e216',
  },
  {
    id: '9d268a4d05fa02be848f6a919a755615',
    label: 'Pink Sheet',
    link_to_crawl: 'https://feeds.feedblitz.com/pink',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/9d268a4d05fa02be848f6a919a755615',
  },
  {
    id: 'e72b488822d7cb48f88a950bb13f3fa7',
    label: 'PM Live ',
    link_to_crawl: 'http://www.pmlive.com/rss/sitewide',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/e72b488822d7cb48f88a950bb13f3fa7',
  },
  {
    id: '2142b04ad9db048ba54a04499d6edc45',
    label: 'Bio Pharma Dive',
    link_to_crawl: 'https://www.biopharmadive.com/feeds/news',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/2142b04ad9db048ba54a04499d6edc45',
  },
  {
    id: '0af558c1125650f43ea529c2c0f63317',
    label: 'Esports Insider',
    link_to_crawl: 'http://www.esportsinsider.com/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/0af558c1125650f43ea529c2c0f63317',
  },
  {
    id: 'a00c9a0e0846d39a418cbcd8a938f9e8',
    label: 'STATNEWS',
    link_to_crawl: 'https://www.statnews.com/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/a00c9a0e0846d39a418cbcd8a938f9e8',
  },
  {
    id: '5c67b4ae5690d6e835bdcd2f501cbfaa',
    label: 'Houzz',
    link_to_crawl: 'http://www.houzz.com/getGalleries/featured/out-rss',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/5c67b4ae5690d6e835bdcd2f501cbfaa',
  },
  {
    id: '856974171f997ced774cf7315f719f8b',
    label: 'Washington Post',
    link_to_crawl: 'http://www.washingtonpost.com/wp-dyn/rss/metro/index.xml',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/856974171f997ced774cf7315f719f8b',
  },
  {
    id: '36afa48e926c95374d389cb61f06eebb',
    label: 'Inside Sports',
    link_to_crawl: 'https://www.insidesport.co/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/36afa48e926c95374d389cb61f06eebb',
  },
  {
    id: '356895435d5f9e8021845906726de40f',
    label: 'The Times of Bengal ',
    link_to_crawl: 'http://www.thetimesofbengal.com/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/356895435d5f9e8021845906726de40f',
  },
  {
    id: '176bc2566566cd2e1fafa3bc37070312',
    label: 'Outsourcing Pharma',
    link_to_crawl:
      'https://www.outsourcing-pharma.com/Info/Outsourcing-Pharma-RSS',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/176bc2566566cd2e1fafa3bc37070312',
  },
  {
    id: 'e6c871ca770cdf2b34d5d4e06e632f78',
    label: 'AM-Online',
    link_to_crawl: 'https://www.am-online.com/news/latest-news/rss.xml',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/e6c871ca770cdf2b34d5d4e06e632f78',
  },
  {
    id: '790c8fc6c72e82fb00fdf90482c34fa3',
    label: 'NDTV Sports',
    link_to_crawl: 'https://sports.ndtv.com/rss/all',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/790c8fc6c72e82fb00fdf90482c34fa3',
  },
  {
    id: '6218a53a4313b47a1e70ff9969d85a25',
    label: 'The Fast Mode',
    link_to_crawl: 'https://www.thefastmode.com/rss-feeds',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/6218a53a4313b47a1e70ff9969d85a25',
  },
  {
    id: '925747815484e792dee20085e792e076',
    label: 'Think Broadband',
    link_to_crawl: 'https://www.thinkbroadband.com/news.rss',
    thumbnail_location: null,
  },
  {
    id: '4e7c1410e816409939903eebfb9b1f74',
    label: 'Tm Inform Inform',
    link_to_crawl: 'https://inform.tmforum.org/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/4e7c1410e816409939903eebfb9b1f74',
  },
  {
    id: 'da18b73e786b968dff6e29ce0a7be30e',
    label: 'Total Tele',
    link_to_crawl: 'https://www.totaltele.com/rss/news',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/da18b73e786b968dff6e29ce0a7be30e',
  },
  {
    id: '26147601f36d555a3342537a8df195cd',
    label: 'Autoblog',
    link_to_crawl: 'https://www.autoblog.com/rss.xml',
    thumbnail_location: null,
  },
  {
    id: 'e3c7b87e1180507e916fe1ec9b549043',
    label: 'Auto Car',
    link_to_crawl: 'https://www.autocar.co.uk/rss',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/e3c7b87e1180507e916fe1ec9b549043',
  },
  {
    id: '7b14a9e21566addbc7b6ae36ef6aee80',
    label: 'Pharmaceutical Business Review',
    link_to_crawl: 'https://www.pharmaceutical-business-review.com/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/7b14a9e21566addbc7b6ae36ef6aee80',
  },
  {
    id: 'ea05cb2fb3e98e962f057f4bcbb23d24',
    label: 'India | The Indian Express',
    link_to_crawl: 'http://indianexpress.com/section/india/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/ea05cb2fb3e98e962f057f4bcbb23d24',
  },
  {
    id: '47481ffe1d8aa644664a3e0c5e57b911',
    label:
      'India News | Latest News Headlines & Live Updates from India - Times of India',
    link_to_crawl:
      'http://timesofindia.indiatimes.com/rssfeeds/-2128936835.cms',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/47481ffe1d8aa644664a3e0c5e57b911',
  },
  {
    id: '8d40e1b699f870c07451e26463e77343',
    label: 'Testing Curator BlogTesting Curator Blog',
    link_to_crawl: 'http://blog.testingcurator.com/feed',
    thumbnail_location:
      'https://idg-stage-bigbucket-images.s3.amazonaws.com/8d40e1b699f870c07451e26463e77343',
  },
  {
    id: 'c2e571ff3293ede2f7e927b7e36f77b8',
    label: 'Create a Website',
    link_to_crawl: 'https://ExampleWordPressWebsite.com/feed',
    thumbnail_location: null,
  },
];

const featuresConfig = {
  feature_groups: [
    {
      id: 'core_features',
      name: 'Core Features',
      description:
        "Essential features that form the foundation of the platform's functionality.",
      features: [
        {
          id: 'assistants',
          name: 'Assistants',
          parents: [
            {
              id: 'ask_needl',
              name: 'Ask Needl',
            },
            {
              id: 'search',
              name: 'Search',
            },
            {
              id: 'apps',
              name: 'Apps',
            },
          ],
        },
        {
          id: 'ask_needl',
          name: 'Ask Needl',
          parents: [
            {
              id: 'search',
              name: 'Search',
            },
            {
              id: 'apps',
              name: 'Apps',
            },
          ],
          childs: [
            {
              id: 'assistants',
              name: 'Assistants',
            },
          ],
        },
        {
          id: 'search',
          name: 'Search',
          childs: [
            {
              id: 'assistants',
              name: 'Assistants',
            },
            {
              id: 'ask_needl',
              name: 'Ask Needl',
            },
          ],
        },
      ],
    },
    {
      id: 'connected_sources',
      name: 'Connected Sources',
      description:
        'Integration and management of external data sources connected to the platform.',
      features: [
        {
          id: 'apps',
          name: 'Apps',
          childs: [
            {
              id: 'assistants',
              name: 'Assistants',
            },
            {
              id: 'ask_needl',
              name: 'Ask Needl',
            },
          ],
        },
        {
          id: 'websites',
          name: 'Websites',
        },
        {
          id: 'capital_markets',
          name: 'Capital Markets',
        },
      ],
    },
    {
      id: 'data_management',
      name: 'Data Management',
      description: 'Tools for organizing, storing, and managing data.',
      features: [
        {
          id: 'my_data',
          name: 'My Data',
        },
        {
          id: 'clipped_reports',
          name: 'Clipped Reports',
        },
        {
          id: 'bookmarks',
          name: 'Bookmarks',
        },
        {
          id: 'tags',
          name: 'Tags',
        },
        {
          id: 'notebooks',
          name: 'Notebooks',
        },
        {
          id: 'needldrive',
          name: 'Needl Drive',
        },
        {
          id: 'portfolio_watchlist',
          name: 'Portfolio Watchlist',
        },
      ],
    },
    {
      id: 'collaboration',
      name: 'Collaboration',
      description:
        'Features that support teamwork, sharing, and communication within the organization.',
      features: [
        {
          id: 'group_feeds',
          name: 'Group Feeds',
        },
        {
          id: 'comments',
          name: 'Comments',
        },
        {
          id: 'suggested_sources',
          name: 'Suggested Sources',
        },
        {
          id: 'publish_feeds',
          name: 'Publish Feeds',
        },
        {
          id: 'copy_feed_template',
          name: 'Copy Feed Template',
        },
        {
          id: 'exit_feed',
          name: 'Exit Feed',
        },
        {
          id: 'shared_tags',
          name: 'Shared Tags',
        },
      ],
    },
    {
      id: 'assistant_types',
      name: 'Assistant Types',
      description: 'Assistant types available on the platform.',
      features: [
        {
          id: 'portfolio_summarization_assistant',
          name: 'Summarization',
        },
        {
          id: 'early_warning_signal_assistant',
          name: 'Early Warning Signal',
        },
        {
          id: 'feed_summary_assistant',
          name: 'Feed Summary',
        },
      ],
    },
    {
      id: 'data_insights',
      name: 'Data Insights',
      description: 'Tools for analyzing and understanding data.',
      features: [
        {
          id: 'doc_summarization',
          name: 'Summarization',
        },
        {
          id: 'doc_sentiment_analysis',
          name: 'Sentiment Analysis',
        },
        {
          id: 'doc_table_extraction',
          name: 'Table Extraction',
        },
      ],
    },
    {
      id: 'data_actions',
      name: 'Data Actions',
      description: 'Options to perform actions on data.',
      features: [
        {
          id: 'doc_download',
          name: 'Download',
        },
        {
          id: 'doc_delete',
          name: 'Delete',
        },
        {
          id: 'feed_filters',
          name: 'Filters',
        },
        {
          id: 'feed_sorting',
          name: 'Sorting',
        },
        {
          id: 'doc_share',
          name: 'Share',
        },
      ],
    },
    {
      id: 'others',
      name: 'Others',
      description: 'Miscellaneous platform features.',
      features: [
        {
          id: 'explore_menu',
          name: 'Explore Menu',
        },
        {
          id: 'my_feeds',
          name: 'My Feeds',
        },
        {
          id: 'needl_feeds',
          name: 'Needl Feeds',
        },
      ],
    },
    {
      id: 'display_options',
      name: 'Display Options',
      description: 'Controls for adjusting content display.',
      features: [
        {
          id: 'doc_full_screen_view',
          name: 'Full Screen View',
        },
        {
          id: 'doc_open_in_source',
          name: 'Open in Source',
        },
        {
          id: 'doc_open_in_new_tab',
          name: 'Open in New Tab',
        },
        {
          id: 'doc_overlay',
          name: 'Overlay',
        },
        {
          id: 'category_tabs',
          name: 'Organize by Category (Category Tabs)',
        },
        {
          id: 'doc_mark_as_read',
          name: 'Mark as Read',
        },
      ],
    },
    {
      id: 'managers',
      name: 'Managers',
      description: 'Tools to help you organize.',
      features: [
        {
          id: 'feed_manager',
          name: 'Feed Manager',
        },
        {
          id: 'tags_manager',
          name: 'Tags Manager',
        },
        {
          id: 'contacts_manager',
          name: 'Manage Contacts',
        },
        {
          id: 'needl_storage',
          name: 'Needl Storage',
        },
      ],
    },
    {
      id: 'notification_and_alerts',
      name: 'Notification and Alerts',
      description: 'Updates and alerts for platform activities.',
      features: [
        {
          id: 'notification',
          name: 'Notification',
        },
        {
          id: 'app_synced_panel',
          name: 'App Synced Panel',
        },
      ],
    },
    {
      id: 'support_and_information',
      name: 'Support and Information',
      description: 'Help resources and platform policies.',
      features: [
        {
          id: 'help_center',
          name: 'Help Center',
        },
        {
          id: 'whats_new',
          name: "What's New",
        },
        {
          id: 'chat_with_us',
          name: 'Chat with Us',
        },
        {
          id: 'submit_feedback',
          name: 'Submit Feedback',
        },
        {
          id: 'terms_of_service',
          name: 'Terms of Service',
        },
      ],
    },
  ],
};

const featuresSettings = {
  /**core_features */
  assistants: true,
  ask_needl: true,
  search: true,

  /**connected_sources */
  apps: true,
  websites: true,
  capital_markets: true,

  /**data_management */
  my_data: true,
  clipped_reports: true,
  bookmarks: true,
  tags: true,
  notebooks: true,
  needldrive: true,
  portfolio_watchlist: true,

  /**collaboration */
  group_feeds: true,
  comments: true,
  suggested_sources: true,
  publish_feeds: true,
  copy_feed_template: true,
  exit_feed: true,
  shared_tags: true,

  /**assistant_types */
  portfolio_summarization_assistant: true,
  early_warning_signal_assistant: true,
  feed_summary_assistant: true,

  /**data insights */
  doc_summarization: true,
  doc_sentiment_analysis: true,
  doc_table_extraction: true,

  /**data_actions */
  doc_download: true,
  doc_delete: true,
  feed_filters: true,
  feed_sorting: true,
  doc_share: true,

  /**others */
  explore_menu: true,
  my_feeds: false,
  needl_feeds: true,
  needl_templates: true,

  /**display_options */
  doc_full_screen_view: true,
  doc_open_in_source: true,
  doc_open_in_new_tab: true,
  doc_overlay: true,
  feed_category_tabs: true,
  doc_mark_as_read: true,

  /**managers */
  feed_manager: true,
  tags_manager: true,
  contacts_manager: true,
  needl_storage: true,

  /**notification_and_alerts */
  notification: true,
  app_synced_panel: true,

  /**support_and_information */
  help_center: true,
  whats_new: true,
  chat_with_us: true,
  submit_feedback: true,
  terms_of_service: true,
};

export const handlers = [
  rest.get(
    'http://localhost:4200/api/organizations/:organization_id/users',
    (req, res, context) => {
      const organizationId = req.params.organization_id;
      const roles = ['member', 'support', 'admin', 'publisher'];

      if (organizationId === '2') {
        roles.push('superadmin');
        roles.splice(roles.indexOf('admin'), 1);
      }

      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          results: {
            users: ALL_USERS_DATA,
            options: {
              role: roles,
              plan: ['free', 'premium'],
            },
          },
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/:organization_id/users/:public_uuid/plan',
    (req: any, res, context) => {
      const { organization_id, public_uuid } = req.params;
      const { plan } = req.body;
      const status = organization_id && public_uuid && plan ? 200 : 500;
      if (status === 200) {
        const index = ALL_USERS_DATA.findIndex((row) => row.id === public_uuid);
        ALL_USERS_DATA[index]['plan'] = plan;
      }
      return res(
        context.status(status),
        context.delay(1000),
        context.json({
          message: 'Plan updated successfully',
        })
      );
    }
  ),
  rest.get(
    'http://localhost:4200/api/organizations/:organizationId/users/:public_uuid',
    (req, res, context) => {
      const index = Math.floor(Math.random() * ALL_USERS_DATA.length);
      const basicInfo = ALL_USERS_DATA[index];
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          result: {
            ...basicInfo,
            feeds_created: 15,
            feeds_shared: 6,
            private_apps: privateIntegrations,
            public_apps: publicIntegrations,
            signup_method: 'email',
          },
        })
      );
    }
  ),
  rest.get('http://localhost:4200/api/admins', (req, res, context) => {
    return res(
      context.status(200),
      context.delay(1000),
      context.json({
        results: ADMIN_USERS_DATA,
      })
    );
  }),
  rest.put(
    'http://localhost:4200/api/organizations/:organization_id/users/:public_uuid/role',
    (req: any, res, context) => {
      const { organization_id, public_uuid } = req.params;
      const { role } = req.body;
      const status = organization_id && public_uuid && role ? 200 : 500;
      if (status === 200) {
        const index = ALL_USERS_DATA.findIndex((row) => row.id === public_uuid);
        ALL_USERS_DATA[index]['role'] = role;
      }
      return res(
        context.status(status),
        context.delay(1000),
        context.json({
          message: 'Role updated successfully',
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/:organization_id/users/:public_uuid/delink',
    (req: any, res, context) => {
      const source = req.body.source;
      const verifiableId = req.body.verifiableId;

      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          message: `User delinked from ${source} successfully for ${verifiableId}`,
        })
      );
    }
  ),
  rest.get('http://localhost:4200/api/permissions', (req, res, context) => {
    return res(
      context.status(200),
      context.delay(1000),
      context.json({
        result: {
          permissions: {
            canViewUserActivityLog: true,
            canDelinkIntegrations: true,
            canChangeUserPlan: true,
            canChangeUserRole: true,
            canMakeUserAdmin: true,
            canCreateOrganization: true,
            canViewOrganization: true,
            canUpdateOrganization: true,
            canDeleteOrganization: true,
            canViewAllOrganizations: true,
            canAddUsersToOrganization: true,
            canViewOrganizationUsers: true,
            canRemoveUsersFromOrganization: true,
            canViewNeedlBox: true,
            canViewFeatureConfig: true,
            canUpdateFeatureConfig: true,
          },
          role: 'superadmin',
          organizationId: 2,
        },
      })
    );
  }),
  rest.get('http://localhost:4200/api/organizations', (req, res, context) => {
    return res(
      context.status(200),
      context.delay(1000),
      context.json({
        results: organizations,
      })
    );
  }),
  rest.get(
    'http://localhost:4200/api/connect/about-apps',
    (req, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          results: privateApps,
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/:organizationId',
    (req: any, res, context) => {
      const { organizationId } = req.params;
      const {
        allow_other_domain_users,
        auto_add_domain_users,
        allow_sharing_outside_org,
      } = req.body;
      const status = organizationId ? 200 : 500;
      if (status === 200) {
        const index = organizations.findIndex(
          (row) => row.id === parseInt(organizationId, 10)
        );

        organizations[index]['allow_other_domain_users'] =
          allow_other_domain_users ??
          organizations[index]['allow_other_domain_users'];
        organizations[index]['auto_add_domain_users'] =
          auto_add_domain_users ??
          organizations[index]['auto_add_domain_users'];
        organizations[index]['allow_sharing_outside_org'] =
          allow_sharing_outside_org ??
          organizations[index]['allow_sharing_outside_org'];
      }

      return res(
        context.status(status),
        context.delay(1000),
        context.json({
          message: 'Organization updated successfully',
        })
      );
    }
  ),
  rest.post(
    'http://localhost:4200/api/organizations',
    (req: any, res, context) => {
      const { organization_name, domain } = req.body;
      const new_organization = {
        id: organizations.length + 1,
        organization_name,
        organization_domain: domain,
        total_users: 0,
        total_admin_users: 0,
        allow_other_domain_users: false,
        auto_add_domain_users: false,
        allow_sharing_outside_org: false,
        is_org_crawl_enabled: false,
        delete_data: true,
        data_deleted: false,
        api_access_key: 'a9e23204-b917-473f-9eb9-18c873c87748',
      };
      organizations.push(new_organization);

      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          message: 'Organization created successfully',
        })
      );
    }
  ),
  rest.post(
    'http://localhost:4200/api/organizations/:organizationId/users',
    (req: any, res, context) => {
      const { email_ids } = req.body;
      const messages = [
        'Added to the organization successfully',
        'Invite email successfully sent',
        'This organization does not allow users from other domains',
      ];
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          results: email_ids.map((email_id: string, i: number) => ({
            email_id,
            message: messages[i] ?? messages[0],
          })),
        })
      );
    }
  ),
  rest.delete(
    'http://localhost:4200/api/organizations/:organizationId',
    (req: any, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          timestamp: 'DD-MM-YYTHH-MM-SS',
          txnId: '12345',
          message: 'Deleted the Organization successfully',
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/restore/:organizationId',
    (req: any, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          timestamp: 'DD-MM-YYTHH-MM-SS',
          txnId: '12345',
          message: 'Restored the Organization successfully',
        })
      );
    }
  ),
  rest.delete(
    'http://localhost:4200/api/organizations/:organizationId/users',
    (req: any, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          timestamp: 'DD-MM-YYTHH-MM-SS',
          txnId: '12345',
          message: 'Deleted the Organization users successfully',
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/:organizationId/users/restore',
    (req: any, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          timestamp: 'DD-MM-YYTHH-MM-SS',
          txnId: '12345',
          message: 'Restored user(s) successfully',
        })
      );
    }
  ),
  rest.get(
    'http://localhost:4200/api/needlbox/public_sources',
    (req, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          sources: publicSources,
        })
      );
    }
  ),
  rest.get(
    'http://localhost:4200/api/needlbox/public_sources/:sourceId/upload_url',
    (req, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          upload_url:
            'http://localhost:4200/api/needlbox/public_sources/sourceId',
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/needlbox/public_sources/:sourceId',
    (req, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          result: 'ok',
        })
      );
    }
  ),
  rest.get('http://localhost:4200/api/limits', (req, res, context) => {
    return res(
      context.status(200),
      context.delay(1000),
      context.json({
        result: {
          is_seats_exceeded: false,
          seats_limit: 10,
          seats_used: 5,
          org_name: 'Oaklane Capital',
        },
      })
    );
  }),
  rest.get(
    'http://localhost:4200/api/organizations/:organizationId',
    (req, res, context) => {
      // getOrganizationController will be called
      const organizationId = Number(req.params.organizationId);
      const organization = organizations.find(
        (organization) => organization.id === organizationId
      );
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          result: {
            ...organization,
            name: organization.organization_name,
            domain: organization.organization_domain,
            created_at: '2021-09-01T00:00:00.000Z',
            created_by: 'createremail@gmail.come',
            updated_at: '2021-09-01T00:00:00.000Z',
            updated_by: 'updateremail@gmail.com',
          },
        })
      );
    }
  ),
  rest.get(
    `http://localhost:4200/api/organizations/features/config`,
    (_, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          feature_groups: featuresConfig.feature_groups,
        })
      );
    }
  ),
  rest.get(
    `http://localhost:4200/api/organizations/:organization_id/features/`,
    (_, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          settings: featuresSettings,
        })
      );
    }
  ),
  rest.put(
    'http://localhost:4200/api/organizations/:organization_id/features/',
    (_, res, context) => {
      return res(
        context.status(200),
        context.delay(1000),
        context.json({
          result: 'ok',
        })
      );
    }
  ),
];
