import {
  Box,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from '@mui/icons-material';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { NEEDL_PAGES } from '../../utils/needl-pages';
import { getFormattedTime } from '../../utils/textUtils';
import { CustomTooltip } from '../common/ToolTip';
import { VerticalDivider } from '../common/VerticalDivider';
import { useFetchOrganization } from '../Users/useFetchOrganization';
import { useConfigurationContext } from './ConfigurationProvider';
import { FeatureGroupToggle } from './FeatureGroupToggle';
import { FeatureToggle } from './FeatureToggle';
import { useFetchFeaturesConfig } from './hooks/useFetchFeaturesConfig';
import { useFetchOrganizationFeatures } from './hooks/useFetchOrganizationFeatures';

const useStyles = makeStyles((theme) => ({
  togglesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    color: theme.palette.primary.main,
    rotate: '-45deg',
    position: 'relative',
    top: '5px',
  },
  description: {
    fontStyle: 'italic',
    marginBottom: '16px',
  },
}));

type RouteParam = { organizationId: string };

export const OrganizationFeatures = () => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const {
    data: config,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useFetchFeaturesConfig();
  const {
    data: organizationFeatureSettings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
  } = useFetchOrganizationFeatures(parseInt(organizationId, 10));
  const {
    data: organization,
    isLoading: orgLoading,
    isError: orgError,
  } = useFetchOrganization(parseInt(organizationId, 10));
  const { state, dispatch } = useConfigurationContext();

  React.useEffect(() => {
    if (organizationFeatureSettings) {
      dispatch({
        type: 'SET_FEATURES',
        payload: {
          features: organizationFeatureSettings,
        },
      });
    }
  }, [organizationFeatureSettings, dispatch]);

  const isLoading = isConfigLoading || isSettingsLoading || orgLoading;

  if (isConfigError || isSettingsError || orgError) {
    toast.error('Error in fetching features data');
  }

  React.useEffect(() => {
    if (
      state.info &&
      state.features &&
      !state.features.group_feeds &&
      state.info.allow_sharing_outside_org
    ) {
      dispatch({
        type: 'TOGGLE_INFO_SETTINGS',
        payload: {
          settingId: 'allow_sharing_outside_org',
        },
      });
    }
  }, [dispatch, state]);

  return (
    <Box>
      <Box className={classes.togglesWrapper}>
        <Box className={classes.header}>
          <Typography variant='h4'>Configuration</Typography>
          <CustomTooltip tooltipTitle='Need help?'>
            <a
              href={NEEDL_PAGES.CONFIG_SUPPORT_URL}
              target='_blank'
              rel='noreferrer noopener'
            >
              <Box className={classes.info}>?</Box>
            </a>
          </CustomTooltip>
          {organization?.updated_at && organization?.updated_by && (
            <Box display='flex' alignItems='center' gridGap={16}>
              <VerticalDivider />
              <Typography variant='body1'>
                Updated on{' '}
                <Box component='span' fontWeight='fontWeightBold'>
                  {getFormattedTime(organization?.updated_at).date}
                </Box>{' '}
                at{' '}
                <Box component='span' fontWeight='fontWeightBold'>
                  {getFormattedTime(organization?.updated_at).time}
                </Box>{' '}
                by{' '}
                <Box component='span' fontWeight='fontWeightBold'>
                  {organization?.updated_by}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
        <Typography color='textSecondary' className={classes.description}>
          Features with a link icon (
          <Link fontSize='small' className={classes.icon} />) require another
          feature to be enabled. Please ensure the parent feature is ON or check
          the dependency.
        </Typography>
        {config && !isLoading ? (
          <>
            {config.map((group) => (
              <FeatureGroupToggle
                key={group.id}
                id={group.id}
                name={group.name}
                description={group.description}
                features={group.features.map((item) => item.id)}
                disabled={isSettingsLoading}
              >
                {group.features.map((feature) => {
                  if (
                    feature.id === 'doc_overlay' ||
                    feature.id === 'category_tabs'
                  ) {
                    return null;
                  }

                  return (
                    <FeatureToggle
                      key={feature.id}
                      id={feature.id}
                      name={feature.name}
                      parents={feature.parents}
                      childs={feature.childs}
                      disabled={isSettingsLoading}
                    />
                  );
                })}
              </FeatureGroupToggle>
            ))}
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};
