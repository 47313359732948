import { Chip } from '@material-ui/core';
import * as React from 'react';
import ReactHotToast from 'react-hot-toast';

import { useRestoreOrg } from '../../hooks/useRestoreOrg';
import { CustomButton } from '../common/CustomButton';

export const OrganizationStatusCell = ({
  organizationId,
  isDeletionPending,
}: OrganizationStatusCellProps) => {
  const restoreOrgMutation = useRestoreOrg();

  const handlerDeleteOrg = () => {
    restoreOrgMutation.mutate(
      { organizationId },
      {
        onSuccess: (data) => {
          ReactHotToast.success(
            data.message || 'Organization restored successfully'
          );
        },
        onError: (error) => {
          ReactHotToast.error(
            (error as string) || 'Failed to restore organization'
          );
        },
      }
    );
  };

  return !isDeletionPending ? (
    <Chip label='Active' color='primary' />
  ) : (
    <CustomButton
      disabled={restoreOrgMutation.isLoading}
      onClick={handlerDeleteOrg}
      size='small'
    >
      Restore
    </CustomButton>
  );
};

type OrganizationStatusCellProps = {
  organizationId: string;
  isDeletionPending: boolean;
};
