import { Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, FileDownloadOutlined } from '@mui/icons-material';
import * as React from 'react';

import { useTableExport } from '../../hooks/useTableExport';
import { TableDataType } from '../../utils/types';
import { CustomButton } from './CustomButton';

export const ExportTable = ({ dataToExport }: ExportTableProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const downloadExcel = useTableExport({ data: dataToExport, type: 'xlsx' });
  const downloadCSV = useTableExport({ data: dataToExport, type: 'csv' });
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handlerXlxsExport = () => {
    downloadExcel();
    handleMenuClose();
  };
  const handlerCSVExport = () => {
    downloadCSV();
    handleMenuClose();
  };

  return (
    <div>
      <CustomButton
        startIcon={<FileDownloadOutlined />}
        endIcon={<ArrowDropDown />}
        onClick={handleMenuOpen}
      >
        Export
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handlerXlxsExport}>Export as xlsx</MenuItem>
        <MenuItem onClick={handlerCSVExport}>Export as CSV</MenuItem>
      </Menu>
    </div>
  );
};

type ExportTableProps = {
  dataToExport: TableDataType;
};
