import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import React from 'react';
import ReactHotToast from 'react-hot-toast';

import { useRemoveorg } from '../../hooks/useRemoveorg';
import { COMMON_ORGANIZATION_IDS } from '../../utils/organizationId';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { CustomButton } from '../common/CustomButton';

export const DeleteOrganization = ({
  selectedOrganizationIds,
  setSelectedOrganizationIds,
}: DeleteOrganizationProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const removeOrgMutation = useRemoveorg();

  const removeOrgHandler = () => {
    if (selectedOrganizationIds.length !== 1) {
      ReactHotToast.error('Please select only one organization');
      return;
    }

    if (COMMON_ORGANIZATION_IDS.includes(selectedOrganizationIds[0])) {
      ReactHotToast.error('Cannot delete common organization');
      return;
    }

    setShowModal(false);
    removeOrgMutation.mutate(
      { organizationId: selectedOrganizationIds[0] },
      {
        onSuccess: () => {
          setSelectedOrganizationIds([]);
          ReactHotToast.success('Organization deleted successfully');
        },
        onError: () => {
          ReactHotToast.error('Failed to delete organization');
        },
      }
    );
  };
  return (
    <>
      <CustomButton
        onClick={() => setShowModal(true)}
        disabled={selectedOrganizationIds.length !== 1}
        color='secondary'
      >
        <DeleteForeverOutlinedIcon style={{ fontSize: 28 }} />
      </CustomButton>
      <ConfirmationDialog
        show={showModal}
        closeDialog={() => setShowModal(false)}
        actionHandler={removeOrgHandler}
        title={'Delete Organization'}
        description={
          <>
            Deletion of the Organization will start after 48 hours. During this
            period, if you wish to recover your organization, please click on
            &apos;Restore&apos;.
          </>
        }
        primaryButtonText='Confirm Deletion'
        primaryButtonColor='secondary'
        primaryButtonVariant='outlined'
        secondaryButtonText='Cancel'
        isLoading={removeOrgMutation.isLoading}
      />
    </>
  );
};

type DeleteOrganizationProps = {
  selectedOrganizationIds: number[];
  setSelectedOrganizationIds: React.Dispatch<React.SetStateAction<number[]>>;
};
