import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1, 3),
  },
}));

export const CustomButton = ({
  variant = 'outlined',
  startIcon = null,
  endIcon = null,
  onClick,
  children,
  className = '',
  disabled = false,
  color,
  size,
}: CustomButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      color={color}
      size={size}
      className={className + ' ' + size ? '' : classes.button}
    >
      {children}
    </Button>
  );
};

type CustomButtonProps = {
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  color?: 'inherit' | 'default' | 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
};
