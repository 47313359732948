import { useMutation } from 'react-query';

import { axiosInstance } from '../axios';
import { useInvalidateFetchUsers } from '../Components/Users/useFetchUsers';

export const useRestoreUsers = () => {
  const invalidateFetchUsers = useInvalidateFetchUsers();

  return useMutation<RestoreUsersResponse, unknown, RestoreUsersArgs>(
    ({ publicUUIDs, organizationId }) =>
      axiosInstance.put(`/organizations/${organizationId}/users/restore`, {
        user_ids: publicUUIDs,
      }),
    {
      onSuccess: (_, variables) => {
        invalidateFetchUsers(variables.organizationId);
      },
    }
  );
};

type RestoreUsersArgs = {
  publicUUIDs: string[];
  organizationId: string;
};

type RestoreUsersResponse = {
  message: string;
};
