import { useMutation } from 'react-query';

import { axiosInstance } from '../axios';
import { useInvalidateFetchOrganizations } from '../Components/Users/useFetchOrganizations';

export const useRemoveorg = () => {
  const invalidateFetchOrganizations = useInvalidateFetchOrganizations();

  return useMutation<RestoreOrgResponse, unknown, RestoreOrgArgs>(
    ({ organizationId }) =>
      axiosInstance.delete(`/organizations/${organizationId}`),
    {
      onSuccess: () => {
        invalidateFetchOrganizations();
      },
    }
  );
};

type RestoreOrgArgs = {
  organizationId: number;
};

type RestoreOrgResponse = {
  message: string;
};
