import { useMutation } from 'react-query';

import { axiosInstance } from '../../axios';
import { useInvalidateUserActivity } from './useFetchUserActivity';

export const useDelinkAppIntegration = () => {
  const invalidateUserActivity = useInvalidateUserActivity();

  return useMutation<
    DelinkAppIntegrationResponse,
    unknown,
    DelinkAppIntegrationArgs
  >(
    ({ source, verifiableId, public_uuid, organizationId }) =>
      axiosInstance.put(
        `/organizations/${organizationId}/users/${public_uuid}/delink`,
        {
          source,
          verifiableId,
        }
      ),
    {
      onSuccess: (_, { organizationId, public_uuid }) => {
        invalidateUserActivity(organizationId, public_uuid);
      },
    }
  );
};

type DelinkAppIntegrationArgs = {
  source: string;
  verifiableId: string;
  public_uuid: string;
  organizationId: string;
};

type DelinkAppIntegrationResponse = {
  message: string;
};
