import { Box, Typography } from '@material-ui/core';
import * as React from 'react';

import { getFormattedTime } from '../../utils/textUtils';
import { TableCellProps } from '../../utils/types';

const DateViewerCell = ({ value }: TableCellProps): JSX.Element => {
  const date = value as string;
  const displayValue = `${getFormattedTime(date).date}, ${
    getFormattedTime(date).time
  }`;

  return (
    <Box>
      <Typography>{displayValue}</Typography>
    </Box>
  );
};

export default DateViewerCell;
