import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { COMMON_ORGANIZATION_IDS } from '../../utils/organizationId';
import {
  capitalizeFirstLetterOfEachWord,
  getAvatarText,
  getFormattedTime,
} from '../../utils/textUtils';
import { UserDetailsType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import { CommonModal, MODAL_TYPE } from '../common/CommonModal';
import { ExportTable } from '../common/ExportTable';
import Spinner from '../common/Spinner';
import { RouteParam } from '.';
import { AddUsersButton } from './AddUsersButton';
import { useFetchOrganization } from './useFetchOrganization';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: '#0078D4',
    marginRight: theme.spacing(2),
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: theme.spacing(5),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontWeight: 'bold',
  },
  createdDate: {
    color: theme.palette.text.secondary,
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const OrganizationInfo = ({ dataToExport }: OrganizationInfoProps) => {
  const classes = useStyles();
  const {
    permissions: { canAddUsersToOrganization },
  } = useGetPermissions();
  const { organizationId } = useParams<RouteParam>();
  const { data: organization, isLoading: orgLoading } = useFetchOrganization(
    parseInt(organizationId, 10)
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  if (!organization || orgLoading) {
    return <Spinner />;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.infoContainer}>
        <Avatar className={classes.avatar}>
          {getAvatarText(organization?.name)}
        </Avatar>
        <Box className={classes.details}>
          <Typography variant='h5' className={classes.name}>
            {capitalizeFirstLetterOfEachWord(organization?.name)}
          </Typography>
          <Typography variant='body2' className={classes.createdDate}>
            Created on{' '}
            <Box component='span' fontWeight='fontWeightBold'>
              {getFormattedTime(organization?.created_at).date}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.actionButtons}>
        {canAddUsersToOrganization &&
          !COMMON_ORGANIZATION_IDS.includes(Number(organizationId)) && (
            <AddUsersButton handleClick={handleModalOpen} />
          )}
        <ExportTable dataToExport={dataToExport} />
      </Box>
      <CommonModal
        modalType={MODAL_TYPE.ADD_USERS}
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </Box>
  );
};

type OrganizationInfoProps = {
  dataToExport: UserDetailsType[];
};
