import { Add as AddIcon } from '@mui/icons-material';
import * as React from 'react';

import { useFetchPricingLimits } from '../../hooks/useFetchPricingLimits';
import { CustomButton } from '../common/CustomButton';

export const AddUsersButton = ({
  handleClick,
}: AddUsersButtonProps): JSX.Element => {
  const { data, isLoading } = useFetchPricingLimits();

  return (
    <CustomButton
      onClick={handleClick}
      disabled={data?.is_seats_exceeded || isLoading}
      startIcon={<AddIcon />}
    >
      Invite User
    </CustomButton>
  );
};

type AddUsersButtonProps = {
  handleClick: () => void;
};
