import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { useTableSearch } from '../../hooks/useTableSearch';
import { COMMON_ORGANIZATION_IDS } from '../../utils/organizationId';
import { OrganizationType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import { CommonModal, MODAL_TYPE } from '../common/CommonModal';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { CustomButton } from '../common/CustomButton';
import { ExportTable } from '../common/ExportTable';
import SearchInput from '../common/SearchInput';
import {
  useFetchOrganizations,
  useInvalidateFetchOrganizations,
} from '../Users/useFetchOrganizations';
import { getColumns } from './columns';
import { DeleteOrganization } from './DeleteOrganization';
import {
  updateOrganizationPayloadType,
  useUpdateOrganization,
} from './useUpdateOrganization';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

type ActionType = {
  payload: updateOrganizationPayloadType;
};

const Organizations = (): JSX.Element => {
  const {
    permissions: {
      canCreateOrganization,
      canViewAllOrganizations,
      canUpdateOrganization,
      canDeleteOrganization,
    },
  } = useGetPermissions();

  const { isLoading, organizations } = useFetchOrganizations();
  const invalidateFetchOrganizations = useInvalidateFetchOrganizations();

  const columns = React.useMemo(
    () => getColumns({ canUpdateOrganization }),
    [canUpdateOrganization]
  );

  const [rows, setRows] = React.useState(organizations);
  const [selectedOrganizationIds, setSelectedOrganizationIds] = React.useState<
    number[]
  >([]);
  const [showDialog, setShowDialog] = React.useState({
    show: false,
    title: '',
    description: '',
    primaryButtonText: '',
    secondaryButtonText: '',
  });
  const [error, setError] = React.useState<string>();
  const [isButtonLoading, setButtonLoading] = React.useState(false);
  const [action, setAction] = React.useState<ActionType>();

  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const { mutateAsync: updateOrganizationInfo } = useUpdateOrganization();

  const { searchValue, handleSearch } = useTableSearch({
    data: organizations,
    setTableRows: (filteredRows) => setRows(filteredRows as OrganizationType[]),
  });

  const dataToExport = React.useMemo(
    () =>
      selectedOrganizationIds.length
        ? rows.filter(({ id }) => selectedOrganizationIds.includes(id))
        : rows,
    [rows, selectedOrganizationIds]
  );

  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    setRows(organizations);
  }, [organizations]);

  const cellClickHandler = ({ field, id }: any) => {
    if (['total_users', 'total_admin_users'].includes(field)) {
      history.push(`/organizations/${id}/users`);
    }

    if (field === 'organization_name_and_domain') {
      history.push(`/organizations/${id}`);
    }
  };

  const toggleConfirmationDialog = (
    show: boolean,
    title = 'Alert',
    description = 'Are you sure ?',
    primaryButtonText = 'Yes',
    secondaryButtonText = 'No'
  ) => {
    setShowDialog({
      show,
      title,
      description,
      primaryButtonText,
      secondaryButtonText,
    });
  };

  const cellValueChangeHandler = ({ field, value, id }: any) => {
    setRows([...rows]);
    if (
      [
        'allow_other_domain_users',
        'auto_add_domain_users',
        'allow_sharing_outside_org',
        'is_org_crawl_enabled',
      ].includes(field)
    ) {
      setAction({
        payload: { id, [field]: value === 'true' },
      });
      if (['is_org_crawl_enabled'].includes(field)) {
        if (value !== 'true') {
          return;
        }
        setAction({
          payload: { id, [field]: value === 'true' },
        });
        toggleConfirmationDialog(
          true,
          'Enable Org level crawl',
          'Are you sure you want to enable org level crawl?',
          'Enable Org level crawl',
          'Cancel'
        );
      } else {
        setAction({
          payload: { id, [field]: value === 'true' },
        });
        toggleConfirmationDialog(true);
      }
    }
  };

  const closeDialog = () => {
    toggleConfirmationDialog(false);
    setError('');
  };

  const actionHandler = async () => {
    try {
      setButtonLoading(true);
      const { status } = await updateOrganizationInfo(action.payload);
      if (status === 200) {
        invalidateFetchOrganizations();
        handleSearch('');
        closeDialog();
      } else {
        setError('Something went wrong! Please try later');
      }
    } catch (e) {
      setError('Something went wrong! Please try later');
    } finally {
      setButtonLoading(false);
    }
  };

  if (!canViewAllOrganizations) return null;

  return (
    <>
      <Box className={classes.rootContainer}>
        <Box className={classes.buttonContainer}>
          <Typography variant='h4'>List of Organizations</Typography>
          <Box display='flex' alignItems='flex-end' gridGap={25}>
            {canCreateOrganization && (
              <CustomButton startIcon={<AddIcon />} onClick={handleModalOpen}>
                Add Organization
              </CustomButton>
            )}
            <ExportTable dataToExport={dataToExport} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' gridGap={25}>
          <SearchInput value={searchValue} handleSearch={handleSearch} />
          {canDeleteOrganization && (
            <DeleteOrganization
              selectedOrganizationIds={selectedOrganizationIds}
              setSelectedOrganizationIds={setSelectedOrganizationIds}
            />
          )}
        </Box>
        <Box className={classes.tableContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
              }}
              rows={rows}
              columns={columns}
              autoPageSize
              disableSelectionOnClick
              isCellEditable={() => true}
              rowHeight={80}
              onCellEditCommit={cellValueChangeHandler}
              onCellClick={cellClickHandler}
              checkboxSelection
              isRowSelectable={(params) => {
                if (COMMON_ORGANIZATION_IDS.includes(params.row.id)) {
                  return false;
                }
                return true;
              }}
              selectionModel={selectedOrganizationIds}
              onSelectionModelChange={(selectedUserIds) => {
                setSelectedOrganizationIds(selectedUserIds as number[]);
              }}
            />
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        show={showDialog.show}
        closeDialog={closeDialog}
        actionHandler={actionHandler}
        errorMessage={error}
        isLoading={isButtonLoading}
        title={showDialog.title}
        description={showDialog.description}
        primaryButtonText={showDialog.primaryButtonText}
        secondaryButtonText={showDialog.secondaryButtonText}
      />
      <CommonModal
        modalType={MODAL_TYPE.ADD_ORGANIZATION}
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default Organizations;
