import { Chip } from '@material-ui/core';
import * as React from 'react';
import ReactHotToast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useRestoreUsers } from '../../hooks/ussRestoreUsers';
import { CustomButton } from '../common/CustomButton';

export const UserStatusCell = ({
  publicUUID,
  isDeletionPending,
}: UserStatusCellProps) => {
  const restoreUsersMutation = useRestoreUsers();
  const { organizationId } = useParams<RouteParam>();

  const handlerRestoreUser = () => {
    restoreUsersMutation.mutate(
      { publicUUIDs: [publicUUID], organizationId },
      {
        onSuccess: (data) => {
          ReactHotToast.success(data.message || 'User restored successfully');
        },
        onError: (error) => {
          ReactHotToast.error((error as string) || 'Failed to restore user');
        },
      }
    );
  };

  return isDeletionPending ? (
    <CustomButton
      disabled={restoreUsersMutation.isLoading}
      onClick={handlerRestoreUser}
      size='small'
    >
      Restore
    </CustomButton>
  ) : (
    <Chip label='Active' color='primary' />
  );
};

type UserStatusCellProps = {
  publicUUID: string;
  isDeletionPending: boolean;
};

type RouteParam = {
  organizationId: string;
};
