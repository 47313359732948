import { makeStyles, Typography } from '@material-ui/core';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import * as React from 'react';
import ReactHotToast from 'react-hot-toast';

import { TableCellProps } from '../../utils/types';
import { CustomButton } from './CustomButton';

const useStyles = makeStyles(() => ({
  cellContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
  },
  cellText: {
    cursor: 'pointer',
    color: '#006D77',
    fontWeight: 500,
  },
}));

const MaskCell = ({
  value,
  maskCharacter = '*',
  maskLength = 6,
}: MaskCellProps) => {
  const classes = useStyles();
  const [isMasked, setIsMasked] = React.useState(true);

  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value as string);
    ReactHotToast.success('Copied to clipboard');
  };

  return (
    <div className={classes.cellContainer}>
      {isMasked ? (
        <Typography className={classes.cellContainer}>
          {typeof value === 'string' &&
            value?.slice(0, maskLength).padEnd(value.length, maskCharacter)}
        </Typography>
      ) : (
        <Typography className={classes.cellContainer}>{value}</Typography>
      )}
      <CustomButton size='small' onClick={toggleMask}>
        {isMasked ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
      </CustomButton>
      <CustomButton size='small' onClick={copyToClipboard}>
        <ContentCopyRoundedIcon />
      </CustomButton>
    </div>
  );
};

type MaskCellProps = TableCellProps & {
  maskCharacter?: string;
  maskLength?: number;
};

export default MaskCell;
