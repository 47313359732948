import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import BasicModal from '../common/BasicModal';
import { useAddUsers } from './useAddUsers';
import { useInvalidateFetchUsers } from './useFetchUsers';

const useStyles = makeStyles(() => ({
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultText: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'blue',
  },
  errorText: {
    textAlign: 'center',
    margin: '0 auto',
    color: 'red',
  },
  emailFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  removeButton: {
    padding: '0',
    marginLeft: '8px',
  },
  addButtonContainer: {
    marginTop: '16px',
    width: '100%',
  },
}));

type AddUsersModalProps = {
  modalOpen: boolean;
  handleModalClose: () => void;
};

type RouteParam = { organizationId: string };

const AddUsers = ({
  handleModalClose,
}: Pick<AddUsersModalProps, 'handleModalClose'>): JSX.Element => {
  const classes = useStyles();
  const { organizationId } = useParams<RouteParam>();
  const [error, setError] = React.useState<string>();
  const [results, setResults] = React.useState<
    { email_id: string; message: string }[]
  >([]);

  const { mutateAsync: addUsers } = useAddUsers();
  const invalidateFetchUsers = useInvalidateFetchUsers();

  return (
    <Formik
      initialValues={{ users: [{ email: '' }] }}
      onSubmit={async ({ users }, { setSubmitting }) => {
        setError('');
        setSubmitting(true);
        try {
          const email_ids = users.map(({ email }) => email);
          const { data } = await addUsers({
            organizationId,
            email_ids,
          });

          invalidateFetchUsers(organizationId);
          setSubmitting(false);
          setResults(data?.results);
          setTimeout(handleModalClose, 3000);
        } catch {
          setSubmitting(false);
          setError('Something went wrong! Try Again Later');
        }
      }}
    >
      {({ values, isSubmitting, isValid }) => (
        <Form>
          <Box className={classes.formFieldsContainer}>
            <Box marginTop='24px'>
              <FieldArray
                name='users'
                render={({ push, remove }) => {
                  return (
                    <Box>
                      {values.users.map((_, index) => (
                        <Box key={index}>
                          <Box className={classes.emailFieldContainer}>
                            <Field
                              name={`users[${index}].email`}
                              required
                              as={TextField}
                              type='email'
                              label={`Email${index + 1}`}
                            />
                            {results.length === 0 && (
                              <Button
                                variant='text'
                                color='primary'
                                type='button'
                                className={classes.removeButton}
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </Box>
                          {results.length > 0 && results[index]?.message && (
                            <Typography>
                              <Box
                                className={classes.resultText}
                                component='span'
                              >
                                {results[index]?.message}
                              </Box>
                            </Typography>
                          )}
                        </Box>
                      ))}
                      {results.length === 0 && (
                        <Button
                          variant='text'
                          color='primary'
                          type='button'
                          onClick={() => push({ email: '' })}
                        >
                          + Add email
                        </Button>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
          </Box>
          {error && (
            <Box width='90%'>
              <Typography>
                <Box className={classes.errorText} component='span'>
                  {error}
                </Box>
              </Typography>
            </Box>
          )}
          {results.length === 0 && (
            <Box className={classes.addButtonContainer}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={values.users.length === 0 || !isValid || isSubmitting}
              >
                Add
              </Button>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

const AddUsersModal = ({
  modalOpen,
  handleModalClose,
}: AddUsersModalProps): JSX.Element => {
  return (
    <BasicModal open={modalOpen} handleClose={handleModalClose}>
      <BasicModal.Title>Add Users</BasicModal.Title>
      <AddUsers handleModalClose={handleModalClose} />
    </BasicModal>
  );
};

export default AddUsersModal;
