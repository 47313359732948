import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '375px',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.shape.borderRadius * 2,
      paddingRight: theme.spacing(1),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));

type SearchInputProps = {
  handleSearch: (search: string) => void;
  value: string;
};

const SearchInput = ({
  value,
  handleSearch,
}: SearchInputProps): JSX.Element => {
  const classes = useStyles();

  return (
    <TextField
      variant='outlined'
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(event.target.value)
      }
      placeholder='Search'
      className={classes.input}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='action' className={classes.searchIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              title='Clear'
              size='small'
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={() => handleSearch('')}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
