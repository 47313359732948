import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';

export const VerticalDivider = ({ height = 25 }: VerticalDividerProps) => {
  return (
    <Box sx={{ height, display: 'flex', alignItems: 'center' }}>
      <Divider orientation='vertical' flexItem sx={{ height: '100%' }} />
    </Box>
  );
};

type VerticalDividerProps = {
  height?: number;
};
