import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '../../axios';
import { UserActivityType } from './../../utils/types';

type ResponseType = {
  result: UserActivityType;
};

type ReturnType = {
  isLoading: boolean;
  userActivity: UserActivityType | undefined;
};

const fetchUserActivity = (organizationId: string, public_uuid: string) => {
  return axiosInstance.get<ResponseType>(
    `/organizations/${organizationId}/users/${public_uuid}`
  );
};

export const useFetchUserActivity = (
  organizationId: string,
  public_uuid: string
): ReturnType => {
  const { data, isLoading } = useQuery(
    ['user_activity', organizationId, public_uuid],
    () => fetchUserActivity(organizationId, public_uuid)
  );
  const userActivity = data?.data.result;

  return { isLoading, userActivity };
};

export const useInvalidateUserActivity = () => {
  const queryClient = useQueryClient();

  return (organizationId: string, public_uuid: string) => {
    queryClient.invalidateQueries([
      'user_activity',
      organizationId,
      public_uuid,
    ]);
  };
};
