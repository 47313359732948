import ReactHotToast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { axiosInstance } from '../axios';
import { useInvalidateFetchUsers } from '../Components/Users/useFetchUsers';

export const useRemoveUsersFromOrganization = (): any => {
  const invalidateFetchUsers = useInvalidateFetchUsers();

  const { mutateAsync } = useMutation(
    ({ organizationId, userIds }: RequestPayloadType) => {
      return axiosInstance.delete(`/organizations/${organizationId}/users`, {
        data: { user_ids: userIds },
      });
    },
    {
      onSuccess: (_, variables) => {
        invalidateFetchUsers(variables.organizationId);
        ReactHotToast.success('Users removed successfully');
      },
    }
  );

  return mutateAsync;
};

type RequestPayloadType = {
  organizationId: string;
  userIds: string[];
};
