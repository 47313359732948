import { Box, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  cellContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    cursor: 'pointer',
  },
}));

export const LabelPairCell = ({
  labelTop,
  labelBottom,
  highlight,
}: LabelPairCellProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.cellContainer}>
      <Typography color={highlight === 'labelTop' ? 'primary' : 'initial'}>
        {labelTop}
      </Typography>
      <Typography color={highlight === 'labelBottom' ? 'primary' : 'initial'}>
        {labelBottom}
      </Typography>
    </Box>
  );
};

type LabelPairCellProps = {
  labelTop: string;
  labelBottom: string;
  highlight?: 'labelTop' | 'labelBottom';
};
