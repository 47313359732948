import { Action } from '../../utils/types';
import { OrganizationInfo } from '../Users/useFetchOrganization';
import { ROLES } from './userRoles';

export const getAlertTitle = (
  action: Action,
  organisation?: OrganizationInfo
): string => {
  switch (action.type) {
    case 'removeUsers':
      return 'Delete Users';
    case 'plan':
      return 'Change user plan';
    case 'role':
      if (
        [ROLES.ADMIN].includes(action.payload?.value) &&
        organisation?.is_org_crawl_enabled
      ) {
        return 'Change Admin';
      }
      return 'Change user role';
    default:
      return 'Alert';
  }
};

export const getAlertDescription = (
  action: Action,
  organisation?: OrganizationInfo
): string => {
  const count = action.payload?.value.length;
  switch (action.type) {
    case 'removeUsers':
      return `Deletion of ${
        count > 1 ? 'users' : 'user'
      } will start after 48 hours. During this period, if you wish to recover account, please click on 'Restore’`;
    case 'role':
      if (
        [ROLES.ADMIN].includes(action.payload?.value) &&
        organisation?.is_org_crawl_enabled
      ) {
        return 'Are you certain you want to change the admin? If confirmed, the new admin will need to re-initiate the organization-wide crawl for all applications.';
      }
      return 'Are you sure ?';
    default:
      return 'Are you sure ?';
  }
};

export const getAlertActionText = (
  action: Action,
  organisation?: OrganizationInfo
): string => {
  switch (action.type) {
    case 'removeUsers':
      return `Confirm Deletion`;
    case 'role':
      if (
        [ROLES.ADMIN].includes(action.payload?.value) &&
        organisation?.is_org_crawl_enabled
      ) {
        return 'Confirm';
      }
      return 'Yes, Change';
    default:
      return 'Yes, Change';
  }
};

export const getAlertSecondaryActionText = (
  action: Action,
  organisation?: OrganizationInfo
): string => {
  switch (action.type) {
    case 'removeUsers':
      return 'Cancel';
    case 'role':
      if (
        [ROLES.ADMIN].includes(action.payload?.value) &&
        organisation?.is_org_crawl_enabled
      ) {
        return 'Cancel';
      }
      return 'No';
    default:
      return 'No';
  }
};
