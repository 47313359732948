import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as React from 'react';

type SelectMenuPropsType = {
  label: string;
  selectedOptionId: number;
  options: {
    id: number;
    label: string;
  }[];
  changeHandler: (value: any) => void;
};

const SelectMenu = ({
  label,
  selectedOptionId,
  options,
  changeHandler,
}: SelectMenuPropsType): JSX.Element => (
  <Box sx={{ minWidth: 120 }}>
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={selectedOptionId}
        label={label}
        onChange={(e: any) => changeHandler(e.target.value)}
      >
        {options.map(({ id, label }) => {
          return (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </Box>
);

export default SelectMenu;
