import LinkOffIcon from '@mui/icons-material/LinkOff';
import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useCheckRBACDelink } from '../../hooks/useCheckRBACDelink';
import { RouteParam } from '../UserActivity';
import { useDelinkAppIntegration } from '../UserActivity/useDelinkAppIntegration';
import ConfirmationDialog from './ConfirmationDialog';
import { CustomButton } from './CustomButton';

export const DelinkIntegration = ({
  source,
  verifiableId,
  isConnected,
}: DelinkIntegrationProps) => {
  const { organizationId, userId } = useParams<RouteParam>();
  const [showModal, setShowModal] = React.useState(false);

  const delinkAppIntegrationMutation = useDelinkAppIntegration();
  const { isLoading, isRBACDelink } = useCheckRBACDelink(
    source,
    organizationId,
    userId
  );
  const { title, description } = getModalMetaData(isRBACDelink);

  const handleDelink = () => {
    delinkAppIntegrationMutation.mutate(
      {
        source,
        verifiableId,
        public_uuid: userId,
        organizationId,
      },
      {
        onSuccess: () => {
          setShowModal(false);
          toast.success('App delinked successfully');
        },
        onError: (error) => {
          toast.error(
            (error as any)?.response?.data?.error || 'Something went wrong'
          );
        },
      }
    );
  };

  if (!isConnected) {
    return null;
  }

  return (
    <>
      <CustomButton
        startIcon={<LinkOffIcon />}
        onClick={() => setShowModal(true)}
        color='secondary'
        size='small'
        disabled={isLoading || delinkAppIntegrationMutation.isLoading}
      >
        Delink
      </CustomButton>
      <ConfirmationDialog
        show={showModal}
        closeDialog={() => setShowModal(false)}
        actionHandler={handleDelink}
        title={title}
        description={description}
        primaryButtonText='Delink App'
        secondaryButtonText='Cancel'
        isLoading={delinkAppIntegrationMutation.isLoading}
      />
    </>
  );
};

const getModalMetaData = (isRBACDelink: boolean) => {
  if (isRBACDelink) {
    return {
      title: 'Delink App',
      description: (
        <>
          This user is an admin, and the app has Role-Based Access control
          enabled. Delinking will stop all data crawling associated with this
          app across the organization.
          <br />
          <br />
          Are you sure you want to proceed?
        </>
      ),
    };
  } else {
    return {
      title: 'Delink App',
      description: (
        <>
          Delinking this app will stop data crawling associated with this app
          for this user.
          <br />
          <br />
          Are you sure you want to proceed?
        </>
      ),
    };
  }
};

type DelinkIntegrationProps = {
  source: string;
  verifiableId: string;
  isConnected: boolean;
};
