import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';

import { useTableSearch } from '../../hooks/useTableSearch';
import { PublicSource } from '../../utils/types';
import SearchInput from '../common/SearchInput';
import { getColumns } from './columns';
import { useFetchPublicSources } from './useFetchPublicSources';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  buttonContainer: {
    display: 'flex',
    width: '720px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    width: '750px',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

export const NeedlBox = (): JSX.Element => {
  const classes = useStyles();
  const { isLoading, isError, sources } = useFetchPublicSources();

  const [rows, setRows] = React.useState(sources);
  const columns = React.useMemo(() => getColumns(), []);

  const { searchValue, handleSearch } = useTableSearch({
    data: sources,
    searchColumns: ['label'],
    setTableRows: (filteredRows) => setRows(filteredRows as PublicSource[]),
  });

  React.useEffect(() => {
    setRows(sources);
  }, [sources]);

  if (isError) {
    throw new Error('Error fetching public sources');
  }

  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.buttonContainer}>
        <Typography variant='h4'>Public Sources</Typography>
        <SearchInput value={searchValue} handleSearch={handleSearch} />
      </Box>
      <Box className={classes.tableContainer}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
            rows={rows}
            columns={columns}
            autoPageSize
            disableSelectionOnClick
          />
        )}
      </Box>
    </Box>
  );
};
