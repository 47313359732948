export const getAvatarText = (name: string) => {
  if (!name) return '';
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};

export const capitalizeFirstLetterOfEachWord = (str: string) => {
  if (!str) return str;
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getFormattedTime = (dateString: string) => {
  if (!dateString) {
    return {
      date: '',
      time: '',
    };
  }

  const date = new Date(dateString);

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);

  return {
    date: formattedDate,
    time: formattedTime,
  };
};
