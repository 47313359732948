import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import UnionIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useFetchPricingLimits,
  useInvalidateFetchPricingLimits,
} from '../../hooks/useFetchPricingLimits';
import { useRemoveUsersFromOrganization } from '../../hooks/useRemoveUsers';
import { useTableSearch } from '../../hooks/useTableSearch';
import {
  COMMON_ORGANIZATION_IDS,
  DEFAULT_ORGANIZATION_ID,
} from '../../utils/organizationId';
import { Action, UserDetailsType } from '../../utils/types';
import { useGetPermissions } from '../../utils/useGetPermissions';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { CustomButton } from '../common/CustomButton';
import {
  getAlertActionText,
  getAlertDescription,
  getAlertSecondaryActionText,
  getAlertTitle,
} from '../common/messages';
import SearchInput from '../common/SearchInput';
import SelectMenu from '../common/SelectMenu';
import { ROLES } from '../common/userRoles';
import { getColumns } from './columns';
import { OrganizationInfo } from './OrganizationInfo';
import { useChangeUserAttribute } from './useChangeUserAttribute';
import { useFetchOrganization } from './useFetchOrganization';
import { useFetchOrganizations } from './useFetchOrganizations';
import { useFetchUsers, useInvalidateFetchUsers } from './useFetchUsers';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '10px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    width: '100%',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  fullPageLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
  },
}));

export type RouteParam = { organizationId: string };

const Users = (): JSX.Element => {
  const {
    permissions: {
      canChangeUserPlan,
      canViewUserActivityLog,
      canChangeUserRole,
      canMakeUserAdmin,
      canViewAllOrganizations,
      canRemoveUsersFromOrganization,
    },
    role,
  } = useGetPermissions();
  const { organizationId } = useParams<RouteParam>();
  const {
    isLoading: orgUsersLoading,
    users,
    options,
  } = useFetchUsers(organizationId);
  const { organizations } = useFetchOrganizations();
  const { data: organization, isLoading: isOrganizationLoading } =
    useFetchOrganization(parseInt(organizationId, 10));
  const invalidateFetchUsers = useInvalidateFetchUsers();
  const invalidatePricingLimits = useInvalidateFetchPricingLimits();

  const columns = React.useMemo(() => {
    const roleOptions = options?.role || [];
    const filteredRoles = canMakeUserAdmin
      ? roleOptions
      : roleOptions.filter((role) => role !== ROLES.ADMIN);

    return getColumns({
      options: {
        ...options,
        role: filteredRoles,
      },
      canChangeUserPlan,
      canChangeUserRole:
        canChangeUserRole && Number(organizationId) !== DEFAULT_ORGANIZATION_ID,
    });
  }, [
    canChangeUserPlan,
    canChangeUserRole,
    canMakeUserAdmin,
    options,
    organizationId,
  ]);

  const [rows, setRows] = React.useState(users);
  const [showDialog, setShowDialog] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [results, setResults] = React.useState<Record<string, any>[]>();
  const [isButtonLoading, setButtonLoading] = React.useState(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<Action>({ type: 'null' });

  const { searchValue, handleSearch } = useTableSearch({
    data: users,
    setTableRows: (filteredRows) => setRows(filteredRows as UserDetailsType[]),
  });

  const dataToExport = React.useMemo(
    () =>
      selectedUserIds.length
        ? rows.filter(({ id }) => selectedUserIds.includes(id))
        : rows,
    [rows, selectedUserIds]
  );

  const changeUserPlan = useChangeUserAttribute('plan');
  const changeUserRole = useChangeUserAttribute('role');
  const removeUsers = useRemoveUsersFromOrganization();

  const history = useHistory();
  const classes = useStyles();
  const { data: priceLimits, isLoading: isPriceLimitsLoading } =
    useFetchPricingLimits();

  React.useEffect(() => {
    setRows(users);
  }, [users]);

  const closeDialog = () => {
    setShowDialog(false);
    setError('');
    setResults([]);
  };

  const mutate = (action: Action) => {
    const { type, payload } = action;
    switch (type) {
      case 'plan': {
        return changeUserPlan({
          organizationId,
          public_uuid: payload.id,
          value: payload.value,
        });
      }
      case 'role': {
        return changeUserRole({
          organizationId,
          public_uuid: payload.id,
          value: payload.value,
        });
      }
      case 'removeUsers': {
        return removeUsers({
          organizationId,
          userIds: payload.value,
        });
      }
    }
  };

  const actionHandler = async () => {
    try {
      setButtonLoading(true);
      const { data } = await mutate(action);
      handleSearch('');
      setSelectedUserIds([]);
      invalidateFetchUsers(organizationId);
      invalidatePricingLimits();
      if (data?.results) setResults(data?.results);
      else closeDialog();
    } catch (e) {
      const errorMsg =
        e?.response?.data?.error || 'Something went wrong! Please try later';
      setError(errorMsg);
    } finally {
      setButtonLoading(false);
    }
  };

  const cellValueChangeHandler = ({ field, value, id }: any) => {
    setRows([...rows]);
    if (['plan', 'role'].includes(field)) {
      setShowDialog(true);
      setAction({
        type: field,
        payload: { id, value },
      });
    }
  };

  const removeUsersHandler = () => {
    setShowDialog(true);
    setAction({
      type: 'removeUsers',
      payload: { value: selectedUserIds },
    });
  };

  const cellClickHandler = ({ field, id }: any) => {
    if (field === 'name_and_email' && canViewUserActivityLog) {
      history.push(`/organizations/${organizationId}/users/${id}`);
    }
  };

  const changeOrganizationHandler = (organizationId: number) => {
    history.push(`/organizations/${organizationId}/users`);
  };

  const isLoading =
    isOrganizationLoading || orgUsersLoading || isPriceLimitsLoading;
  if (isLoading) {
    return (
      <Box className={classes.fullPageLoader}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.rootContainer}>
        <OrganizationInfo dataToExport={dataToExport} />
        <Box className={classes.buttonContainer}>
          <Box display='flex' alignItems='center' gridGap={25}>
            {canViewAllOrganizations && organizations.length > 0 && (
              <SelectMenu
                label='Organization'
                selectedOptionId={parseInt(organizationId, 10)}
                options={organizations.map(({ id, organization_name }) => ({
                  id,
                  label: organization_name,
                }))}
                changeHandler={changeOrganizationHandler}
              />
            )}
            <SearchInput value={searchValue} handleSearch={handleSearch} />
            {canRemoveUsersFromOrganization && (
              <CustomButton
                onClick={() => {
                  removeUsersHandler();
                  setSelectedUserIds([]);
                }}
                disabled={!selectedUserIds.length}
                color='secondary'
              >
                <DeleteForeverOutlinedIcon style={{ fontSize: 28 }} />
              </CustomButton>
            )}
          </Box>
        </Box>
        {!isPriceLimitsLoading &&
          priceLimits?.seats_used === priceLimits?.seats_limit && (
            <Box display='flex' flexDirection='row' marginTop='14px'>
              <Box paddingRight='8px'>
                <UnionIcon />
              </Box>
              <Typography>
                Your <b>Teams</b> plan is at its maximum number of members
                allowed.
                <Link
                  href={`${process.env.REACT_APP_MAIN_URL}/settings/user-settings`}
                  color='#006D77'
                  underline='always'
                >
                  &nbsp;Upgrade your plan and add more members.
                </Link>
              </Typography>
            </Box>
          )}
        <Box className={classes.tableContainer}>
          <DataGrid
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
            rows={rows}
            columns={columns}
            autoPageSize
            disableSelectionOnClick
            isCellEditable={() => true}
            onCellEditCommit={cellValueChangeHandler}
            onCellClick={cellClickHandler}
            checkboxSelection
            rowHeight={80}
            selectionModel={selectedUserIds}
            onSelectionModelChange={(selectedUserIds) => {
              setSelectedUserIds(selectedUserIds as string[]);
            }}
          />
        </Box>
        <ConfirmationDialog
          show={showDialog}
          closeDialog={closeDialog}
          actionHandler={actionHandler}
          errorMessage={error}
          results={results}
          isLoading={isButtonLoading}
          title={getAlertTitle(action, organization)}
          description={getAlertDescription(action, organization)}
          primaryButtonText={getAlertActionText(action, organization)}
          secondaryButtonText={getAlertSecondaryActionText(
            action,
            organization
          )}
        />
      </Box>
    </>
  );
};

export default Users;
