import { Theme } from '@emotion/react';
import { createTheme } from '@material-ui/core/styles';

export const getTheme = (): Theme =>
  createTheme({
    palette: {
      primary: {
        main: '#006D77',
      },
      secondary: {
        main: '#FF8080',
      },
      background: {
        default: '#ffffff',
      },
      action: {
        hover: '#F6F4EC',
        selected: '#F6F4EC',
      },
      text: {
        primary: '#2c2c2c',
        secondary: '#545856',
      },
    },
    typography: {
      fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: '6rem',
        fontWeight: 'lighter',
      },
      h2: {
        fontSize: '3.75rem',
        fontWeight: 'lighter',
      },
      h3: {
        fontSize: '3rem',
      },
      h4: {
        fontSize: '2rem',
      },
      h5: {
        fontSize: '1.5rem',
      },
      h6: {
        fontSize: '1.25rem',
      },
      subtitle1: {
        fontSize: '1rem',
      },
      subtitle2: {
        fontSize: '0.875rem',
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.875rem',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none', // removes uppercase transformation
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          // minWidth: '100px !important',
        },
      },
      MuiCircularProgress: {
        root: {
          color: '#006D77',
        },
      },
    },
  });
